import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const CityList = (props) => {
  const classes = useStyles();
  const [jumiaCities, setJumiaCities] = useState([]);
  const [selectedJumiaCity, setSelectedJumiaCity] = useState({
    label: "Map City...",
    value: "",
  });

  useEffect(() => {
    const loadMatchedJumiaCities = async () => {
      const response = await fetch(
        "https://jumia.palletman.com.au/public/jumia-cities",
        {
          method: "POST",
          headers: { "Content-Type": "text/plain" },
          body: JSON.stringify({
            id: props.addressId,
            stateId: props.stateId,
          }),
        }
      );

      const resData = await response.json();

      setJumiaCities(resData.jumia_city_list);
      setSelectedJumiaCity(resData.jumia_city_list[0]);
      props.onSelectCity(resData.jumia_city_list[0]);
    };
    loadMatchedJumiaCities();
  }, [props.addressId]);

  const onchangeSelect = (item) => {
    setSelectedJumiaCity(item);
    props.onSelectCity(item);
  };

  return (
    <div className={classes.input}>
      <Typography variant="subtitle2" gutterBottom>
        City
      </Typography>
      <Select
        value={selectedJumiaCity}
        onChange={onchangeSelect}
        options={jumiaCities}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
      />
    </div>
  );
};

export default CityList;
