import React, { useState, useEffect, useRef } from "react";
import EmployeeForm from "./EmployeeForm";
import { CSVLink } from "react-csv";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  Container,
  Button,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import * as employeeService from "../../services/employeeService";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";

import FileCopyIcon from "@material-ui/icons/FileCopy";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Popup from "../../components/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
import StateList from "../../components/address/StateList";
import StreetFinder from "../../components/address/StreetFinder";
import CityList from "../../components/address/CityList";
import PageHeader from "../../components/PageHeader";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "60%",
  },
  newButton: {
    right: "10px",
    position: "absolute",
  },
  formContainer: {
    marginTop: "20px",
  },
}));

const headCells = [
  { id: "state", label: "State" },
  { id: "address", label: "Full Address" },
  { id: "jumiaCity", label: "City Mapping" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default function Employees() {
  const classes = useStyles();
  const csvLink = useRef();
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState({
    label: "Select state...",
    value: "",
  });
  const [selectedAddress, setSelectedAddress] = useState({
    label: "",
    value: "",
  });

  const [selectedCity, setSelectedCity] = useState({
    label: "",
    value: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const loadStates = async () => {
      const response = await fetch(
        "https://jumia.palletman.com.au/public/get-all-state"
      );

      const resData = await response.json();

      setStates(resData.states);
    };
    loadStates();
  }, []);

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.address.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addToList = () => {
    if (!selectedState || !selectedAddress || !selectedCity) {
      console.log("Operation not allowed!");
      return;
    }
    const tempList = [...records];
    tempList.push({
      id: records.length + 1,
      state: selectedState.label,
      address: selectedAddress.label,
      jumiaCity: selectedCity.label,
    });

    setSelectedState({ label: "Select state...", value: "" });
    setSelectedAddress({ label: "", value: "" });
    setSelectedCity({ label: "", value: "" });
    setRecords(tempList);

    setNotify({
      isOpen: true,
      message: "Added Successfully",
      type: "success",
    });
  };

  const addOrEdit = (employee, resetForm) => {
    if (employee.id === 0) employeeService.insertEmployee(employee);
    else employeeService.updateEmployee(employee);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    setRecords(employeeService.getAllEmployees());
    setNotify({
      isOpen: true,
      message: "Submitted Successfully",
      type: "success",
    });
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    employeeService.deleteEmployee(id);
    setRecords(employeeService.getAllEmployees());
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  const onchangeSelect = (item) => {
    setSelectedState(item);
  };

  const onSelectAddressHandler = (address) => {
    setSelectedAddress(address);
  };

  const onSelectCityHandler = (city) => {
    setSelectedCity(city);
  };

  const handleCapture = ({ target }) => {
    setSelectedFile(target.files[0]);
  };

  const handleSubmit = async () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("fileToUpload", selectedFile);

    const response = await fetch(
      "https://jumia.palletman.com.au/public/upload",
      {
        method: "POST",
        body: formData,
      }
    );

    const resData = await response.json();

    if (resData.status === "SUCCESSFUL") {
      setRecords(resData.data);
      setSelectedFile(null);
    } else {
      console.log("API call Failed!");
    }
  };

  const handleDownload = () => {
    csvLink.current.link.click();
    setRecords([]);
  };

  return (
    <>
      <PageHeader
        title="Upload CSV"
        subTitle="Please adhere to file structure"
        icon={<FileCopyIcon fontSize="large" />}
        handleCapture={handleCapture}
        handleSubmit={handleSubmit}
        selectedFile={selectedFile}
      />
      <div className={classes.formContainer}>
        <Container maxWidth="sm">
          <StateList
            selectedState={selectedState}
            onchangeSelect={onchangeSelect}
            states={states}
          />
          <StreetFinder
            stateId={selectedState.value}
            onSelectAddress={onSelectAddressHandler}
          />
          <CityList
            stateId={selectedState.value}
            addressId={selectedAddress.value}
            onSelectCity={onSelectCityHandler}
          />
          <div>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={addToList}
            >
              Add
            </Button>
          </div>
        </Container>
      </div>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <Controls.Input
            label="Search Address"
            className={classes.searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />

          <Controls.Button
            text="Download CSV"
            variant="outlined"
            startIcon={<CloudDownloadIcon />}
            className={classes.newButton}
            onClick={handleDownload}
          />
          <CSVLink
            data={records}
            filename="mapped.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </Toolbar>
        <TblContainer>
          <TblHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.state}</TableCell>
                <TableCell>{item.address}</TableCell>
                <TableCell>{item.jumiaCity}</TableCell>
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      console.log("Edit");
                    }}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      console.log("Delete");
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="Employee Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <EmployeeForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
