import React from "react";
import {
  Paper,
  Card,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Controls from "../components/controls/Controls";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AttachmentIcon from "@material-ui/icons/Attachment";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
  },
  pageHeader: {
    padding: theme.spacing(4),
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  pageIcon: {
    display: "inline-block",
    padding: theme.spacing(2),
    color: "#3c44b1",
  },
  pageTitle: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(6),
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  fileRoot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  faceImage: {
    color: theme.palette.primary,
  },
}));

export default function PageHeader(props) {
  const classes = useStyles();
  const { title, subTitle, icon, handleCapture, selectedFile, handleSubmit } =
    props;
  return (
    <Paper elevation={0} square className={classes.root}>
      <div className={classes.pageHeader}>
        <Card className={classes.pageIcon}>{icon}</Card>
        <div className={classes.pageTitle}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {subTitle}
          </Typography>
        </div>

        <div className={classes.fileRoot}>
          <input
            accept=".csv"
            className={classes.input}
            id="faceImage"
            type="file"
            onChange={handleCapture}
          />
          <Tooltip title="Select CSV">
            <label htmlFor="faceImage">
              <IconButton
                className={classes.faceImage}
                color="primary"
                aria-label="upload file"
                component="span"
              >
                <AttachmentIcon fontSize="large" />
              </IconButton>
            </label>
          </Tooltip>
          <label>{selectedFile ? selectedFile.name : "Select CSV"}</label>. . .
        </div>

        {selectedFile && (
          <Controls.Button
            text="Upload CSV"
            variant="outlined"
            startIcon={<CloudUploadIcon />}
            onClick={handleSubmit}
          />
        )}
      </div>
    </Paper>
  );
}
