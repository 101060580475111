import React from "react";
import { makeStyles, withStyles } from "@material-ui/core";

// withStyles & makeStyles

const style = {
  sideMenu: {
    display: "flex",
    flexDirection: "column",
    position: "fixed",
    left: "0px",
    width: "320px",
    height: "100%",
    backgroundColor: "#253053",
    overflowX: "hidden",
  },
};

const SideMenu = (props) => {
  const { classes } = props;
  return <div className={classes.sideMenu}></div>;
};

export default withStyles(style)(SideMenu);
