import React from "react";
import Select from "react-select";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const StateList = (props) => {
  const classes = useStyles();
  const { selectedState, onchangeSelect, states } = props;
  return (
    <div className={classes.input}>
      <Typography variant="subtitle2" gutterBottom>
        State
      </Typography>
      <Select
        value={selectedState}
        onChange={onchangeSelect}
        options={states}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
      />
    </div>
  );
};

export default StateList;
