import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const StreetFinder = (props) => {
  const classes = useStyles();
  const [stateId, setStateId] = useState(null);
  const [selectedStreet, setSelectedStreet] = useState();

  useEffect(() => {
    if (!props.stateId) {
      setSelectedStreet(null);
    } else {
      setStateId(props.stateId);
    }
  }, [props.stateId]);

  const onChangeHandler = (street) => {
    setSelectedStreet(street);
    props.onSelectAddress(street);
  };

  const loadOptions = async (inputText, callback) => {
    const response = await fetch(
      "https://jumia.palletman.com.au/public/search-street",
      {
        method: "POST",
        headers: { "Content-Type": "text/plain" },
        body: JSON.stringify({
          inputText: inputText,
          stateId: stateId,
        }),
      }
    );

    const resData = await response.json();

    callback(
      resData.data.map((item) => ({
        ...item,
      }))
    );
  };

  return (
    <div className={classes.input}>
      <Typography variant="subtitle2" gutterBottom>
        Address
      </Typography>
      <AsyncSelect
        value={selectedStreet}
        onChange={onChangeHandler}
        placeholder="Type street..."
        loadOptions={loadOptions}
      />
    </div>
  );
};

export default StreetFinder;
